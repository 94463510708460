import './CoursesPage.scss'
import React from 'react'
import Layout from "../../components/layout";
import SEO from "../../components/seo";

const CoursesPage = () => (
    <Layout>
        <SEO title="Courses"/>
        Courses
    </Layout>
);

export default CoursesPage
